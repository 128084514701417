export const questionnaire = [
  {
    question: "How are you doing on the current medication?",
    answers: [
      {
        answer: "Well",
        showConditional: false,
      },
      {
        answer: "Not well",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about what you’re experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Current height",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "Current Weight",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  // {
  //   question: "BMI",
  //   answers: ["freeText"],
  //   type: "freeText",
  //   isConditional: false,
  // },
  {
    question: "Have you had any side effects from the current medication?",
    answers: [
      {
        answer: "No",
        showConditional: false,
      },
      {
        answer: "Yes",
        showConditional: true,
      },
    ],
    type: "radio",
  },
  {
    question: "Please tell us more about the side effects you are experiencing",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Are you ready to increase your dose or stay at the same dose?",
    answers: [
      {
        answer: "Increase",
        showConditional: false,
      },
      {
        answer: "Stay at the same dose",
        showConditional: false,
      },
      {
        answer: "Stop the medication",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question: "Anything else you’d like to share with your doctor?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: false,
  },
];
